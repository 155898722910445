<template>
  <div>
    <v-card elevation="8" max-width="300" height="408" >
      <v-card-text>
        <v-img height="180" contain src="@/assets/myvms.png"></v-img>
      </v-card-text>
      <v-card-title>Le mie virtual machine</v-card-title>
      <v-card-text>
        <v-row align=center class="mx-0">
          VM configurate
          <span>
            <v-chip v-if="error" class="ma-2">?</v-chip>
            <v-chip v-else-if="!loading" class="ma-2">{{ vmsLength }}</v-chip>
            <v-chip v-else class="ma-2"
              ><v-progress-circular
                indeterminate
                size="8"
                width="2"
              ></v-progress-circular
            ></v-chip>
          </span>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col class="text-right">
          <v-btn
            color="primary"
            class="ma-2"
            @click="$router.push({ path: '/myvms' })"
          >
            Dettagli
          </v-btn></v-col
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "WidgetVMs",
  data() {
    return {
      vms: [],
      loading: false,
      error: false,
      vmsLength: '?',
    };
  },
  mounted: function () {
    this.loadVMs();
  },
  methods: {
    loadVMs: function () {
      this.loading = true;
      this.error = false;
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/vms/" + this.$store.state.userMatricola + '/mylist', {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          this.vms = response.data;
          this.vmsLength = this.vms.length;
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    },
  },
};
</script>
